import React from "react"

import Image from "../components/image"
import SEO from "../components/seo"
import GcLayout from "../components/GcLayout"
import GcPostLink from "../components/GcPostLink"

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => (
      <div className="p-4 shadow border-gray-200 border-solid md:border-none border-2 m-2 md:m-0 md:transition duration-500 ease-in-out md:transform hover:-translate-y-1">
        <GcPostLink key={edge.node.id} post={edge.node} imageClasses={'h-64 md:h-32 lg:h-40 xl:h-48 w-auto md:transition duration-500 ease-in-out md:transform hover:-translate-y-1 hover:skew-x-3 hover:shadow hover:scale-105'} />
      </div>
    ))
  return (
    <GcLayout>
      <SEO title="Home" />
      <div className="container mx-auto">
        <div className="w-full text-center rounded border-gray-300 border-4 py-4 px-4 mt-4 mb-12 md:transition duration-500 ease-in-out md:transform hover:-translate-y-1 hover:scale-105 hover:shadow-xl">
          <h1 className="text-4xl sm:text-6xl lg:text-8xl text-gray-700 pt-0">I'm Garrett Cornwell</h1>
          <h2 className="text-2xl sm:text-3xl lg:text-4xl text-gray-600 pb-o">Full-stack web engineer</h2>
        </div>

        <div>
          <h1 className="w-full mt-2 text-gray-700 font-semibold">
            Recent Blog Posts
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">{Posts}</div>
        </div>
      </div>
    </GcLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { path: { glob: "/blog/**" } } }
      limit: 3
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 125)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            path
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
